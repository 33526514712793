import { withSitecoreContext, Text } from "@sitecore-jss/sitecore-jss-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { ReCaptcha } from "react-recaptcha-v3";

import config from "../../temp/config";
import PasswordToggle from "../../utilities/FrontFunctions/FormElements/PasswordToggle";
import CustomSelect from "../../utilities/FrontFunctions/FormElements/CustomSelect";
import SingleFileUpload from "../../utilities/FrontFunctions/FormElements/SingleFileUpload";
import IconSVG from "../../utilities/FrontFunctions/IconSVG";
import DefaultModal from "../Modal/DefaultModal";
import { getApiDatas, postApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions";

const axios = require("axios");

class PageInfoPerso extends Component {
	constructor(props) {
		super(props);
		this.state = {
			serverError: false,
			serverErrorUpdate: false,
			serverErrorUpdatePro: false,
			serverErrorChangePassword: false,
			serverErrorUpdatePicture: false,
			stepModalDeleteAccount: 1,
			submitUploadVisible: false,
			serverErrorUpdateLabel: "",
			serverErrorUpdateProLabel: "",
			serverErrorChangePasswordLabel: "",

			UrlInfosPerso_ChangePassword: `${config.sitecoreApiHost}/apiaox/Call/InfosPerso_Password`,
			UrlInfosPerso_UpdatePicture: `${config.sitecoreApiHost}/apiaox/Call/InfosPerso_Picture`,
			UrlInfosPerso_Delete: `${config.sitecoreApiHost}/apiaox/Call/InfosPerso_Delete`,

			apiDatas:[],
			initDataOK: false,
            currentSalePoint:null,
		};

		this.handleClickDelete = this.handleClickDelete.bind(this);
	}
	handleClickDelete(e, datakey) {
		axios
			.post(this.state.UrlInfosPerso_Delete, queryString.stringify({ userkey: datakey }), {
				withCredentials: true,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((result) => {
				this.setState({
					formSubmitted: result.data.IsValid,
					serverErrorDelete: !result.data.IsValid,
					serverErrorDeleteLabel: result.data.ExceptionMessage,
				});
				if (result.data.IsValid) {
					this.setState({ stepModalDeleteAccount: 2 });
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					serverErrorDelete: true,
					serverErrorDeleteLabel: this.props.t("DefaultServerError"),
				});
			});
	}

    componentDidMount() {
		getApiDatas(`/apiaox/Call/GetDataPageRegister`).then((apiDatas) => {
			this.setState({
                apiDatas: apiDatas,
                currentSalePoint : {...apiDatas?.User?.Salepoint,SalePointIsChallenge:apiDatas?.User?.IsChallenge},
                initDataOK: apiDatas?.Postes && apiDatas?.User
            });
		});
	}

	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		const { CurrentUser } = this.props.fields.data;
		const apiDatas = this.state.apiDatas;
		return (
			<section className="section_form">
				<div className="str_container">
					<div className="ctn_top">{oCurrentItemFields && <Text field={oCurrentItemFields["Page Title"]} tag="h1"></Text>}</div>

					<div className="ctn_form">
                        {!this.state.initDataOK ? (
                            <div className="obj_form">
                                {/* TODO SGU Msg */}
                                <p className="t_error mb_30 fade-in">Error init Form </p>
                            </div>
                        ) : (
                            <>
                                <div className="grid_container">
                                    <Formik
                                        initialValues={{
                                            oldpassword: "",
                                            password: "",
                                            passwordconfirm: "",
                                            recaptcha: "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            oldpassword: Yup.string().required(),
                                            password: Yup.string()
                                                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*)(+=._-])[A-Za-z\d!@#$%^&*)(+=._-]{8,30}$/, this.props.t("Register_passwordDetails"))
                                                .required(),
                                            passwordconfirm: Yup.string()
                                                .test("passwords-match", this.props.t("Register_PasswordsMustMatch"), function (value) {
                                                    return this.parent.password === value;
                                                })
                                                .required(),
                                            recaptcha: Yup.string().required(),
                                        })}
                                        onSubmit={(values, actions) => {
                                            document.body.classList.add("is-loading");

                                            this.setState({
                                                formSubmitted: false,
                                                serverErrorChangePassword: false,
                                                serverErrorChangePasswordLabel: "",
                                            });

                                            axios
                                                .post(this.state.UrlInfosPerso_ChangePassword, queryString.stringify(values), {
                                                    withCredentials: true,
                                                    headers: {
                                                        Accept: "application/json",
                                                        "Content-Type": "application/x-www-form-urlencoded",
                                                    },
                                                })
                                                .then((result) => {
                                                    this.setState({
                                                        formSubmitted: result.data.IsValid,
                                                        serverErrorChangePassword: !result.data.IsValid,
                                                        serverErrorChangePasswordLabel: result.data.ExceptionMessage,
                                                    });

                                                    document.body.classList.remove("is-loading");
                                                    actions.resetForm();
                                                    this.MainPasswordToggle.destroy();

                                                    if (result.data.IsValid) {
                                                        this.ModalChangePassword.openModal();
                                                    }

                                                    if (this.recaptchaPassword) {
                                                        this.recaptchaPassword.execute();
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    this.setState({
                                                        serverErrorChangePassword: true,
                                                        serverErrorChangePasswordLabel: this.props.t("DefaultServerError"),
                                                    });

                                                    if (this.recaptchaPassword) {
                                                        this.recaptchaPassword.execute();
                                                    }

                                                    document.body.classList.remove("is-loading");
                                                    actions.resetForm();
                                                    this.MainPasswordToggle.destroy();
                                                });
                                        }}
                                    >
                                        {({ handleSubmit, handleBlur, setFieldValue, setFieldTouched, errors, touched, values }) => (
                                            <div className="grid_item grid_mdp">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="ctn_contact">
                                                        <h3>{this.props.t("Register_ModifierMonMotDePasse")}</h3>
                                                        <div className="obj_form">
                                                            <div className="ctn_input mb_30">
                                                                <PasswordToggle
                                                                    setFieldValue={setFieldValue}
                                                                    handleBlur={handleBlur}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    eyeMode={true}
                                                                    fieldName="oldpassword"
                                                                    placeholder={this.props.t("Register_AncienMotDePasse")}
                                                                />
                                                            </div>
                                                            <div className="ctn_input mb_30 var_password">
                                                                <PasswordToggle
                                                                    onRef={(ref) => (this.MainPasswordToggle = ref)}
                                                                    setFieldValue={setFieldValue}
                                                                    handleBlur={handleBlur}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    eyeMode={true}
                                                                    fieldName="password"
                                                                    placeholder={this.props.t("Register_passwordPlaceholder")}
                                                                    strengthLevel={true}
                                                                />
                                                                <div className="obj_infobulle">
                                                                    <div className="info_picto">?</div>
                                                                    <div className="info_text">
                                                                        <span>{this.props.t("Register_passwordDetails")}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="ctn_input mb_30">
                                                                <PasswordToggle
                                                                    setFieldValue={setFieldValue}
                                                                    handleBlur={handleBlur}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    eyeMode={true}
                                                                    fieldName="passwordconfirm"
                                                                    placeholder={this.props.t("Register_passwordConfirm")}
                                                                />
                                                            </div>
                                                            <ReCaptcha
                                                                ref={(ref) => (this.recaptchaPassword = ref)}
                                                                sitekey={this.props.t("recaptcha_sitekey")}
                                                                action="PersoChangePassword"
                                                                verifyCallback={(recaptchaToken) => {
                                                                    setFieldValue("recaptcha", recaptchaToken);
                                                                }}
                                                            />
                                                            {this.state.serverErrorChangePasswordLabel && <p className="t_error mb_30 fade-in">{this.state.serverErrorChangePasswordLabel}</p>}
                                                            {errors.passwordconfirm && touched.passwordconfirm && <p className="t_error mb_30 fade-in">{this.props.t("Register_passwordError")}</p>}
                                                            {((errors.oldpassword && touched.oldpassword) ||
                                                                (errors.password && touched.password) ||
                                                                (errors.passwordconfirm && touched.passwordconfirm) ||
                                                                errors.recaptcha) && (
                                                                <p className="t_error mb_30 fade-in">
                                                                    {this.props.t("DefaultErrorMessage")}
                                                                    {console.log(errors)}{" "}
                                                                </p>
                                                            )}
                                                            <div className="ctn_cta">
                                                                <button className="obj_cta" type="submit">
                                                                    {this.props.t("Register_Valider")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <DefaultModal onRef={(ref) => (this.ModalChangePassword = ref)} htmlContent={<p className="t_main">{this.props.t("Register_NouveauMotDePasse")}</p>} />
                                            </div>
                                        )}
                                    </Formik>

                                    <Formik
                                        initialValues={{
                                            fileupload: null,
                                            recaptcha: "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            fileupload: Yup.mixed().required(),
                                            recaptcha: Yup.string().required(),
                                        })}
                                        onSubmit={(values, actions) => {
                                            document.body.classList.add("is-loading");

                                            var formData = new FormData();
                                            Object.entries(values).forEach((elt) => formData.append(elt[0], elt[1]));

                                            axios({
                                                method: "POST",
                                                url: this.state.UrlInfosPerso_UpdatePicture,
                                                data: formData,
                                                withCredentials: true,
                                                headers: {
                                                    "Content-Type": "multipart/form-data",
                                                },
                                            })
                                                .then((res) => {
                                                    this.setState({
                                                        formSubmitted: true,
                                                        serverErrorUpdatePicture: !res.data.IsValid,
                                                        serverErrorUpdatePictureLabel: res.data.ExceptionMessage,
                                                    });

                                                    document.body.classList.remove("is-loading");

                                                    actions.resetForm();
                                                    // reset file upload
                                                    this.SingleFileUploadAvatar.destroy();

                                                    document.getElementsByClassName("UserImg")[0].src = res.data.Value.InfosPerso_UserImg + "?key=" + Math.random();

                                                    if (this.recaptchaAvatar) {
                                                        this.recaptchaAvatar.execute();
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.error("err: ", err);
                                                    this.setState({
                                                        serverErrorUpdatePicture: true,
                                                        serverErrorUpdatePictureLabel: this.props.t("DefaultServerError"),
                                                    });

                                                    document.body.classList.remove("is-loading");

                                                    actions.resetForm();
                                                    // reset file upload
                                                    this.SingleFileUploadAvatar.destroy();

                                                    if (this.recaptchaAvatar) {
                                                        this.recaptchaAvatar.execute();
                                                    }
                                                });
                                        }}
                                    >
                                        {({ handleSubmit, handleBlur, setFieldValue, setFieldTouched, errors, touched, values }) => (
                                            <div className="grid_item grid_img-upload">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="obj_form">
                                                        <div className="ctn_file-upload mb_30 var_avatar var_edit">
                                                            <SingleFileUpload
                                                                onRef={(ref) => (this.SingleFileUploadAvatar = ref)}
                                                                setFieldValue={setFieldValue}
                                                                setFieldTouched={setFieldTouched}
                                                                errors={errors}
                                                                touched={touched}
                                                                fieldName={"fileupload"}
                                                                labelText={" "}
                                                                ctaCancelText={this.props.t("Register_uploadCancel")}
                                                                formatErrorText={this.props.t("Register_uploadFormat")}
                                                                sizeErrorText={this.props.t("Register_uploadSize")}
                                                                loadingErrorText={this.props.t("Register_uploadError")}
                                                                thumbSize={90}
                                                                onUpload={() => {
                                                                    this.setState({
                                                                        submitUploadVisible: true,
                                                                    });
                                                                }}
                                                                onUnload={() => {
                                                                    this.setState({
                                                                        submitUploadVisible: false,
                                                                    });
                                                                }}
                                                            />
                                                            <div className="ctn_edit-cta">
                                                                {CurrentUser.userimg !== "" ? (
                                                                    <img src={CurrentUser.userimg} className="UserImg" alt={CurrentUser.firstname + " " + CurrentUser.lastname} />
                                                                ) : (
                                                                    <img src={require("../../assets/images/v_avatar-xl.png")} alt={CurrentUser.firstname + " " + CurrentUser.lastname} className="UserImg" />
                                                                )}
                                                                <div className="bt_edit-cta">
                                                                    <div>
                                                                        <IconSVG icon="avatar_edit" />
                                                                        <span className="t_edit">{this.props.t("Register_ModifierMaPhotoDeProfil")}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ReCaptcha
                                                            ref={(ref) => (this.recaptchaAvatar = ref)}
                                                            sitekey={this.props.t("recaptcha_sitekey")}
                                                            action="PersoChangeAvatar"
                                                            id="recaptchaAvatar"
                                                            verifyCallback={(recaptchaToken) => {
                                                                setFieldValue("recaptcha", recaptchaToken);
                                                            }}
                                                        />
                                                        {this.state.serverErrorUpdatePicture && <p className="t_error mb_30 fade-in">{this.state.serverErrorUpdatePictureLabel}</p>}
                                                        {this.state.serverError && <p className="t_error mb_30 fade-in">{this.state.serverErrorMessageUpdatePicture}</p>}
                                                        {((errors.fileupload && touched.fileupload) || errors.recaptcha) && <p className="t_error mb_30 fade-in">{this.props.t("DefaultErrorMessage")}</p>}
                                                        {this.state.submitUploadVisible && (
                                                            <div className="ctn_cta mb_30 fade-in">
                                                                <button className="obj_cta" type="submit">
                                                                    {this.props.t("Register_Valider")}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        )}
                                    </Formik>

                                    <Formik
                                        initialValues={{
                                            civilite: CurrentUser.gender,
                                            lastname: CurrentUser.lastname,
                                            firstname: CurrentUser.firstname,
                                            birthday: CurrentUser.birthday,
                                            birthmonth: CurrentUser.birthmonth,
                                            birthyear: CurrentUser.birthyear,
                                            email: CurrentUser.email,
                                            phone: CurrentUser.phone,
                                            address: CurrentUser.adress,
                                            address_bis: CurrentUser.adress2,
                                            postal_code: CurrentUser.postalcode,
                                            city: CurrentUser.city,

                                            salepoint: "",
                                            poste: apiDatas.User.PosteId,
                                            acceptNwl: CurrentUser.acceptNewsLetter,
                                            acceptNotifications: CurrentUser.acceptNotifications,

                                            securiteSociale: CurrentUser.codeSocialSecurity,
                                            noSS: CurrentUser.noSocialSecurity,

                                            recaptcha: "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            civilite: Yup.string().required(),
                                            lastname: Yup.string().required(),
                                            firstname: Yup.string().required(),
                                            email: Yup.string().email().required(),
                                            phone: Yup.string().required(),

                                            salepoint: Yup.string().matches(/^\d{8}$/),
                                            poste: Yup.string().required(),
                                            securiteSociale: Yup.string().matches(
                                                /^([1-37-8])([0-9]{2})(0[0-9]|[2-35-9][0-9]|[14][0-2])((0[1-9]|[1-8][0-9]|9[0-69]|2[abAB])(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)|(9[78][0-9])(0[1-9]|[1-8][0-9]|90))(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(0[1-9]|[1-8][0-9]|9[0-7])$/,
                                                ""
                                            ),

                                            recaptcha: Yup.string().required(),
                                        })}
                                        onSubmit={(values) => {
                                            document.body.classList.add("is-loading");
                                            const valuesWithSalePoint = values.salepoint ? values : {...values, salepoint:apiDatas.User.Salepoint.Code}
                                            postApiDatas(`/apiaox/Call/InfosPerso_Update`, valuesWithSalePoint).then((res) => {
                                                this.setState({
                                                    formSubmitted: res.IsValid,
                                                    serverErrorUpdate: !res.IsValid,
                                                    serverErrorUpdateLabel: res.Message,
                                                });

                                                if (this.recaptchaInfoPerso) {
                                                    this.recaptchaInfoPerso.execute();
                                                }

                                                document.body.classList.remove("is-loading");

                                                if(res.IsValid){
                                                   getApiDatas(`/apiaox/Call/GetDataPageRegister`).then((apiDatas) => {
                                                        this.setState({ apiDatas: apiDatas })})
                                                    this.UpdateConfirm.openModal();
                                                }
                                            });
                                        }}
                                    >
                                        {({ handleSubmit, handleBlur, setFieldValue, setFieldTouched,setErrors,onBlur, onChange, errors, touched, values }) => (
                                            <div className="grid_item grid_form-perso">
                                                <form onSubmit={handleSubmit}>
                                                    <h2>{this.props.t("Register_InformationsPersonnelles")}</h2>
                                                    <div className="obj_form">
                                                        <div className="mb_30">
                                                            <CustomSelect
                                                                fieldName={"civilite"}
                                                                placeholder={this.props.t("Register_genderPlaceholder")}
                                                                options={[
                                                                    { value: "0", label: this.props.t("Register_genderFemale") },
                                                                    { value: "1", label: this.props.t("Register_genderMale") },
                                                                    { value: "2", label: this.props.t("Register_genderNotDefined") },
                                                                ]}
                                                                setFieldValue={setFieldValue}
                                                                setFieldTouched={setFieldTouched}
                                                                errors={errors}
                                                                touched={touched}
                                                                values={values}
                                                                defaultValue={CurrentUser.gender.toString()}
                                                            />
                                                        </div>
                                                        <div className="ctn_input mb_30">
                                                            <Field
                                                                autoComplete="off"
                                                                className={errors.lastname && touched.lastname && "error"}
                                                                as="input"
                                                                type="text"
                                                                name="lastname"
                                                                placeholder={this.props.t("Register_lastnamePlaceholder")}
                                                            />
                                                        </div>
                                                        <div className="ctn_input mb_30">
                                                            <Field
                                                                autoComplete="off"
                                                                className={errors.firstname && touched.firstname && "error"}
                                                                as="input"
                                                                type="text"
                                                                name="firstname"
                                                                placeholder={this.props.t("Register_firstnamePlaceholder")}
                                                            />
                                                        </div>
                                                        <div className="ctn_select-birthdate mb_30">
                                                            <label className="t_label">Date de naissance</label>
                                                            <div className="ctn_select-wrapper">
                                                                <CustomSelect
                                                                    fieldName={"birthday"}
                                                                    placeholder={CurrentUser.birthday !== "-1" ? CurrentUser.birthday : this.props.t("Register_DateDeNaissanceJour")}
                                                                    options={"DayConfig"}
                                                                    setFieldValue={setFieldValue}
                                                                    setFieldTouched={setFieldTouched}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    values={values}
                                                                />
                                                                <CustomSelect
                                                                    fieldName={"birthmonth"}
                                                                    placeholder={CurrentUser.birthmonth !== "-1" ? CurrentUser.birthmonth : this.props.t("Register_DateDeNaissanceMois")}
                                                                    options={"MonthConfig"}
                                                                    setFieldValue={setFieldValue}
                                                                    setFieldTouched={setFieldTouched}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    values={values}
                                                                />
                                                                <CustomSelect
                                                                    fieldName={"birthyear"}
                                                                    placeholder={CurrentUser.birthyear !== "-1" ? CurrentUser.birthyear : this.props.t("Register_DateDeNaissanceAnnee")}
                                                                    options={"YearConfig"}
                                                                    setFieldValue={setFieldValue}
                                                                    setFieldTouched={setFieldTouched}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    values={values}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info" id="GoToAddress">
                                                            <Field
                                                                autoComplete="off"
                                                                className={errors.email && touched.email && "error"}
                                                                as="input"
                                                                type="email"
                                                                name="email"
                                                                placeholder={this.props.t("Register_emailPlaceholder")}
                                                                disabled={true}
                                                            />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_emailDetails")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field autoComplete="off" as="input" type="text" 
                                                                className={errors.phone && touched.phone && "error"}
                                                                name="phone" 
                                                                placeholder={this.props.t("Register_PhonePlaceholder")} 
                                                            />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_PhoneDetail")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field autoComplete="off" as="input" type="text" name="address" placeholder={this.props.t("Register_AdressPlaceholder")} />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_AdressDetail")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field autoComplete="off" as="input" type="text" name="address_bis" placeholder={this.props.t("Register_Adress2Placeholder")} />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_Adress2Detail")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field autoComplete="off" as="input" type="text" name="postal_code" placeholder={this.props.t("Register_CodePostalPlaceholder")} />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_CodePostalDetail")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field autoComplete="off" as="input" type="text" name="city" placeholder={this.props.t("Register_CityPlaceholder")} />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_CityDetail")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h2>
                                                        <br />
                                                        {this.props.t("Register_InformationsProfessionnelles")}
                                                    </h2>
                                                    <div className="obj_form">
                                                        <div className="ctn_select-birthdate mb_30">
                                                            <label className="t_label">{this.props.t("Register_VotrePDV")}</label>
                                                            <div className="ctn_input mb_30">
                                                            <Field
                                                                autoComplete="off"
                                                                className={errors.salepoint && touched.salepoint && "error"}
                                                                as="input"
                                                                type="text"
                                                                name="salepoint"
                                                                placeholder={`${apiDatas.User.Salepoint.Code} - ${apiDatas.User.Salepoint.Name}`}
                                                                onBlur={async(e) => {
                                                                    handleBlur(e)
                                                                    if(e?.target?.value?.length===8){
                                                                        const dataResult = await getApiDatas(`/apiaox/Call/CheckSalePointFromCode?salepointCode=`+ e.target.value)
                                                                        if(!dataResult?.SalePoint?.SalePointID){
                                                                            setErrors({salepoint:this.props.t("Register_SalePointInexistant")})
                                                                            this.setState({currentSalePoint: apiDatas.User.Salepoint})
                                                                        }else{ this.setState({currentSalePoint: dataResult.SalePoint})}
                                                                    } else{ this.setState({currentSalePoint: apiDatas.User.Salepoint})}

                                                                    
                                                                }}
                                                            />
                                                            
                                                            </div>
                                                            {errors.salepoint && touched.salepoint && <p className="t_error mb_30 fade-in">{this.props.t("Register_SalePointInexistant")}</p>}
                                                        </div>
                                                        {apiDatas.Postes && 
                                                            <div className="mb_30">
                                                                <CustomSelect
                                                                    fieldName={"poste"}
                                                                    placeholder={this.props.t("Register_postePlaceholder")}
                                                                    options={ apiDatas.Postes.map((poste) => ({ 
                                                                        value: poste.FrontOfficeUserPoste_Id, 
                                                                        label: poste.FrontOfficeUserPoste_Name
                                                                    })) }
                                                                    setFieldValue={setFieldValue}
                                                                    setFieldTouched={setFieldTouched}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    values={values}
                                                                    defaultValue={apiDatas.User.PosteId}
                                                                />
                                                            </div>
                                                        }
                                                        <div className="ctn_checkbox_background mb_30">
                                                            <p className="t_title">{this.props.t("Register_AcceptNewsletter")}</p>
                                                            <div className="ctn_line">
                                                                <Field type="checkbox" id="acceptNwl" name="acceptNwl" className="switch_checkbox" />
                                                                <label className="switch_checkbox_label" htmlFor="acceptNwl">
                                                                    <span></span> {this.props.t("Register_acceptNwl")}
                                                                </label>
                                                            </div>
                                                            <p className="t_desc">{this.props.t("Register_undoConsent")}</p>
                                                        </div>
                                                    </div>

                                                    {this.state.currentSalePoint?.SalePointIsChallenge && 
                                                    <>
                                                        <h2>{this.props.t("Register_Challenges")}</h2>
                                                        <div className="obj_form">
                                                            <p className="t_mention mb_30">{this.props.t("Register_NeedSS")}</p>

                                                            <div className="ctn_input mb_30 var_info">
                                                                <Field
                                                                    className={errors.securiteSociale && touched.securiteSociale && "error"}
                                                                    as="input"
                                                                    type="text"
                                                                    name="securiteSociale"
                                                                    placeholder={this.props.t("Register_SecuriteSociale")}
                                                                />
                                                            </div>
                                                            {errors.securiteSociale && touched.securiteSociale && <p className="t_error mb_30 fade-in">{this.props.t("Register_SecuriteSocialeInvalide")}</p>}
                                                            <div className="ctn_checkbox mb_30">
                                                                <Field type="checkbox" id="noSS" name="noSS" />
                                                                <label className="" htmlFor="noSS">
                                                                    {this.props.t("Register_NoSS")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }

                                                    <div className="obj_form">
                                                        <p
                                                            className="t_mention mb_30"
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.props.t("MonCompte_CGU"),
                                                            }}
                                                        ></p>

                                                        <p className="t_mandatory mb_30">{this.props.t("Register_mandatory")}</p>

                                                        <ReCaptcha
                                                            ref={(ref) => (this.recaptchaInfoPerso = ref)}
                                                            sitekey={this.props.t("recaptcha_sitekey")}
                                                            action="PersoChangeInfoPerso"
                                                            id="recaptchaInfoPerso"
                                                            verifyCallback={(recaptchaToken) => {
                                                                setFieldValue("recaptcha", recaptchaToken);
                                                            }}
                                                        />

                                                        {this.state.serverErrorUpdateLabel && <p className="t_error mb_30 fade-in">{this.state.serverErrorUpdateLabel}</p>}

                                                        {((errors.civilite && touched.civilite) ||
                                                            (errors.firstname && touched.firstname) ||
                                                            (errors.lastname && touched.lastname) ||
                                                            (errors.email && touched.email) ||
                                                            (errors.poste && touched.poste) ||
                                                            (errors.salepoint && touched.salepoint) ||
                                                            errors.recaptcha) && <p className="t_error mb_30 fade-in">{this.props.t("DefaultErrorMessage")}</p>}
                                                        <div className="ctn_cta">
                                                            <button className="obj_cta" type="submit">
                                                                {this.props.t("Register_MettreAJour")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        )}
                                    </Formik>
                                    <DefaultModal
                                        onRef={(ref) => (this.UpdateConfirm = ref)}
                                        htmlContent={
                                            <>
                                                <p className="t_title">Mes informations personnelles</p>
                                                <p className="t_main">votre compte est mis à jour</p>
                                                <div className="ctn_cta">
                                                    <button className="obj_cta" type="button" onClick={() => this.UpdateConfirm.closeModal()}>fermer</button>
                                                </div>
                                                {/* TODO SGU : refresh */}
                                            </>
                                        }
                                    />

                                    <div className="grid_item grid_suppr">
                                        <h2>{this.props.t("PageInfosPerso_SupprimerMonCompte")}</h2>
                                        <div className="obj_form">
                                            <div className="ctn_cta">
                                                <button
                                                    className="obj_cta"
                                                    type="button"
                                                    onClick={() => this.ModalDeleteAccount.openModal()}
                                                    dangerouslySetInnerHTML={{ __html: this.props.t("PageInfosPerso_SupprimeMonCompte") }}
                                                ></button>
                                                {this.state.serverErrorDelete && <p className="t_error mb_30 fade-in">{this.state.serverErrorDeleteLabel}</p>}
                                                <DefaultModal
                                                    onRef={(ref) => (this.ModalDeleteAccount = ref)}
                                                    htmlContent={
                                                        <>
                                                            {this.state.stepModalDeleteAccount === 1 && (
                                                                <>
                                                                    <p className="t_title">{this.props.t("PageInfosPerso_SuppressionDeCompte")}</p>
                                                                    <p className="t_main" dangerouslySetInnerHTML={{ __html: this.props.t("PageInfosPerso_VotreCompteEtVosDonnees") }}></p>
                                                                    <div className="ctn_cta">
                                                                        <button className="obj_cta" type="button" onClick={(e) => this.handleClickDelete(e, CurrentUser.userkey)}>
                                                                            {this.props.t("Reponse_Oui")}
                                                                        </button>
                                                                        <button className="obj_cta" type="button" onClick={() => this.ModalDeleteAccount.closeModal()}>
                                                                            {this.props.t("Reponse_Non")}
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {this.state.stepModalDeleteAccount === 2 && <p className="t_main">{this.props.t("PageInfosPerso_VotreDemandeSeraTraiteeDansLesMeilleures")}</p>}
                                                        </>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Bug ReCaptcha, il en faut 1 dernier pour que les autres fonctionne !! */}
                                <ReCaptcha ref={(ref) => (this.recaptchaAutre = ref)} sitekey={this.props.t("recaptcha_sitekey")} elementID="recaptchaAutre" />
                            </>
                        )}
                    </div>
				</div>
			</section>
		);
	}
}

export default withTranslation()(withSitecoreContext()(PageInfoPerso));
