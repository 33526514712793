import React, { Component } from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { ReCaptcha  } from "react-recaptcha-v3";
import { withTranslation } from "react-i18next";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

import Modal from 'react-modal'
import DefaultModal from '../Modal/DefaultModal'

import { postApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions";


const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class InfosModalMAJ extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	render() {
		return (
			<div>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin popin_small"
					closeTimeoutMS={300}
					contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
                        <Formik
                            initialValues={{
                                email: '',
                                recaptcha: "",
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email().required(),
                                recaptcha: Yup.string().required(),
                            })}
                            onSubmit={(values) => {
                                document.body.classList.add('is-loading')

                                postApiDatas('/apiaox/Call/Invitation', values)
                                .then((res) => {
                                    this.setState({
                                        InvitationServerError: !res.IsValid,
                                        InvitationServerErrorMessage: res.ExceptionMessage,
                                    });

                                    if(res.IsValid){
                                        this.closeModal();
                                        this.ModalInvitationConfirm.openModal();
                                    }

                                    if (this.recaptchaInvitation) {
                                        this.recaptchaInvitation.execute();
                                    }

                                    document.body.classList.remove("is-loading");
                                });
                            }}
                        >
                            { ({ handleSubmit, handleBlur, setFieldValue, setFieldTouched, errors, touched, values }) => (
                                <div className="grid_item grid_form-perso">
                                    <form onSubmit={handleSubmit}>
                                        <p className="t_title">Nouveau collaborateur</p>
                                        <p className="t_main">Saisissez l'adresse email du collaborateur afin de lui adresser une invitation</p>

                                        <div className="obj_form">
                                            <div className="ctn_input mb_30 var_info">
                                                <Field className={errors.email && touched.email && 'error'} as="input" type="email" name="email" placeholder="E-mail professionnel*" />
                                                <div className="obj_infobulle">
                                                    <div className="info_picto">?</div>
                                                    <div className="info_text"><span>Si vous n’avez pas d'e-mail professionnel, remplissez par une adresse e-mail secondaire.</span></div>
                                                </div>
                                            </div>

                                            <ReCaptcha
                                                ref={(ref) => (this.recaptchaInvitation = ref)}
                                                sitekey={this.props.t("recaptcha_sitekey")}
                                                action="invitation"
                                                verifyCallback={(recaptchaToken) => {
                                                    document.body.classList.remove('is-loading');
                                                    if (recaptchaToken.length > 0) {
                                                        this.setState({
                                                            ...this.state, 
                                                            isRecaptchaLoaded: true, 
                                                            errorMessage: ''
                                                        });
                                                        setFieldValue("recaptcha", recaptchaToken, false);
                                                    }
                                                }}
                                            />

                                            {( (errors.email && touched.email) ) && 
                                                <p className="t_error mb_30 fade-in">Il y a des erreurs dans le formulaire.</p>
                                            }
                                            {( (errors.recaptcha) ) && 
                                                <p className="t_error mb_30 fade-in">Erreur de recaptcha.</p>
                                            }
                                            {(this.state.InvitationServerError) && (
                                                <p className="t_error mb_20 fade-in">{this.state.InvitationServerErrorMessage}</p>
                                            )}

                                            <div className="ctn_cta">
                                                <button className="obj_btn" type="submit">Valider</button>
											    <button className="obj_btn light" onClick={this.closeModal}>Annuler</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </Formik>
					</div>
				</Modal>

                <DefaultModal onRef={(ref) => (this.ModalInvitationConfirm = ref)} htmlContent={<p className="t_main">Le mail d'invitation a été envoyé</p>} />
			</div>
		)
	}
}

export default withTranslation()(withSitecoreContext()(InfosModalMAJ));