import React, { Component } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { ReCaptcha } from "react-recaptcha-v3";
import config from "../../temp/config";
import { withTranslation } from "react-i18next";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Redirect } from "react-router-dom";
import queryString from 'query-string';

import PasswordToggle from "../../utilities/FrontFunctions/FormElements/PasswordToggle";
import CustomSelect from "../../utilities/FrontFunctions/FormElements/CustomSelect";
import SingleFileUpload from "../../utilities/FrontFunctions/FormElements/SingleFileUpload";
import IconSVG from "../../utilities/FrontFunctions/IconSVG";
import { getApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions";

const axios = require("axios");

class Inscription extends Component {
	constructor(props) {
		super(props);

		this.state = {
			initPdvOK: false,
			initDataOK: false,
			serverErrorRegistration: false,
			formSubmitted: false,
			UrlRegister_Step2: `${config.sitecoreApiHost}/apiaox/Call/RegisterForm_Step2`,
			apiDatas: [],
            salePoint:null,
            paramMail:null,
		};

    }

	componentDidMount() {
        var oParams = queryString.parse(window.location.search);
		if(oParams.code_client !== undefined){
            getApiDatas(`/apiaox/Call/CheckSalePointFromCode?salepointCode=`+ oParams.code_client).then((resCheckSalePointFromCode)=>{
                this.setState({
                    salePoint: resCheckSalePointFromCode?.SalePoint,
                    initPdvOK: !!resCheckSalePointFromCode?.SalePoint,
                 });
            })
        }
		getApiDatas(`/apiaox/Call/GetDataPageRegister`).then((apiDatas) => {
			this.setState({ 
                apiDatas: apiDatas,
                initDataOK:!!apiDatas?.Postes
            });
		});
    }


	render() {
		const { PageContact, PageLogin } = this.props.fields.data;
		const apiDatas = this.state.apiDatas;
		if (this.state.salePoint === undefined) {
			return <Redirect to="/" />;
		}

		return (
			<section className="section_form">
				<div className="str_container">
					<div className="ctn_top">
						<h1>{this.props.t("Register_title")}</h1>
						{!this.state.formSubmitted && this.state.initPdvOK && (
							<>
								<h2>{this.props.t("Register_subTitle")}</h2>
								<h3>
									{this.state.salePoint?.SalePointLabel} - {this.state.salePoint?.SalePointCode}
								</h3>
								<p>
									{this.props.t("Register_description")} <br />
									{this.props.t("Register_subDescription")}
								</p>
							</>
						)}
					</div>

					{/* TODO de même sur les 2 autres pages */}
					{!this.state.initPdvOK || !this.state.initDataOK ? (
						<div className="ctn_form">
							<div className="ctn_col col_left">
								<div className="ctn_contact">
									<h3>{this.props.t("Register_contactQuestion")}</h3>
									<h4>{this.props.t("Register_contactByMail")}</h4>
									<p className="ctn_cta">
										<a className="obj_cta var_picto var_email" href={PageContact && PageContact.url}>
											<IconSVG icon="email" width="21" height="17" />
											{this.props.t("Register_contactSubmitText")}
										</a>
									</p>
									<h4>{this.props.t("Register_contactByPhone")}</h4>
									<p className="t_tel" dangerouslySetInnerHTML={{ __html: this.props.t("Register_contactPhone") }}></p>
									<p className="t_mention">{this.props.t("Register_contactByPhonePrice")}</p>
								</div>
							</div>
							<div className="ctn_col col_right">
								<div className="obj_form">
									{/* TODO SGU Msg */}
									<p className="t_error mb_30 fade-in">Error init Form </p>
								</div>
							</div>
						</div>
					) : this.state.formSubmitted && this.state.serverErrorRegistration === false ? (
						<>
							<div className="ctn_form var_confirm">
								<div className="obj_form">
									<p className="t_mandatory mb_30">{this.props.t("Register_Insertion")}</p>
									<div className="ctn_cta">
										<a className="obj_cta" href={PageLogin.url}>
											{this.props.t("Register_LogIn")}
										</a>
									</div>
								</div>
							</div>
						</>
					) : (
						<Formik
							initialValues={{
								fileupload: null,

								civilite: "",
								firstname: "",
								lastname: "",
								email: queryString.parse(window.location.search)?.Mail || "",
								emailconfirm: "",
								password: "",
								passwordconfirm: "",
								phone: "",
								address: "",
								address_bis: "",
								postal_code: "",
								city: "",

								poste: "",
								acceptNwl: false,
								acceptNotifications: false,

								securiteSociale: "",
								noSS: false,

								recaptcha: "",
							}}
							validationSchema={Yup.object().shape({
								civilite: Yup.string().required(),
								firstname: Yup.string().required(),
								lastname: Yup.string().required(),
								email: Yup.string().email().required().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,this.props.t("DefaultErrorMessage")),
								emailconfirm: Yup.string()
									.test("emails-match", "E-mails must match...", function (value) {
										return this.parent.email === value;
									})
									.required(),
								password: Yup.string()
									.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*)(+=._-])[A-Za-z\d!@#$%^&*)(+=._-]{8,30}$/, this.props.t("Register_passwordDetails"))
									.required(),
								passwordconfirm: Yup.string()
									.test("passwords-match", this.props.t("Register_PasswordsMustMatch"), function (value) {
										return this.parent.password === value;
									})
									.required(),
                                phone: Yup.string().required(),
								poste: Yup.string().required(),
								securiteSociale: Yup.string().matches(
									/^([1-37-8])([0-9]{2})(0[0-9]|[2-35-9][0-9]|[14][0-2])((0[1-9]|[1-8][0-9]|9[0-69]|2[abAB])(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)|(9[78][0-9])(0[1-9]|[1-8][0-9]|90))(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(0[1-9]|[1-8][0-9]|9[0-7])$/,
									""
								),

								recaptcha: Yup.string().required(),
							})}
							onSubmit={(values, actions) => {
								document.body.classList.add("is-loading");

								var formData = new FormData();
								Object.entries(values).forEach((elt) => formData.append(elt[0], elt[1]));
								formData.append("ID_SalePointItem", this.state.salePoint?.SalePointCode);

								axios({
									method: "POST",
									url: this.state.UrlRegister_Step2,
									data: formData,
									headers: {
										"Content-Type": "multipart/form-data",
									},
								})
                                .then((res) => {
                                    this.setState({
                                        formSubmitted: true,
                                        serverErrorRegistration: !res.data.IsValid,
                                        serverErrorMessageRegistration: res.data.ExceptionMessage,
                                    });
                                    document.body.classList.remove("is-loading");

                                    if (this.recaptchaInscription) {
                                        this.recaptchaInscription.execute();
                                    }
                                })
                                .catch((err) => {
                                    console.error("err: ", err);
                                    this.setState({
                                        serverErrorRegistration: true,
                                        serverErrorMessageRegistration: this.props.t("DefaultServerError"),
                                    });

                                    if (this.recaptchaInscription) {
                                        this.recaptchaInscription.execute();
                                    }

                                    document.body.classList.remove("is-loading");
                                });
							}}
						>
							{({ handleSubmit, handleBlur, setFieldValue, setFieldTouched, errors, touched, values }) => (
								<form onSubmit={handleSubmit}>
                                    <div className="ctn_form">
                                        <div className="ctn_col col_left">
                                            <div className="obj_form">
                                                <div className="ctn_file-upload mb_30 var_avatar">
                                                    <SingleFileUpload
                                                        onRef={(ref) => (this.SingleFileUploadAvatar = ref)}
                                                        setFieldValue={setFieldValue}
                                                        errors={errors}
                                                        touched={touched}
                                                        fieldName={"fileupload"}
                                                        labelText={this.props.t("Register_uploadAdd")}
                                                        ctaCancelText={this.props.t("Register_uploadCancel")}
                                                        formatErrorText={this.props.t("Register_uploadFormat")}
                                                        sizeErrorText={this.props.t("Register_uploadSize")}
                                                        loadingErrorText={this.props.t("Register_uploadError")}
                                                        thumbSize={90}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ctn_contact">
                                                <h3>{this.props.t("Register_contactQuestion")}</h3>
                                                <h4>{this.props.t("Register_contactByMail")}</h4>
                                                <p className="ctn_cta">
                                                    <a className="obj_cta var_picto var_email" href={PageContact && PageContact.url}>
                                                        <IconSVG icon="email" width="21" height="17" />
                                                        {this.props.t("Register_contactSubmitText")}
                                                    </a>
                                                </p>
                                                <h4>{this.props.t("Register_contactByPhone")}</h4>
                                                <p className="t_tel" dangerouslySetInnerHTML={{ __html: this.props.t("Register_contactPhone") }}></p>
                                                <p className="t_mention">{this.props.t("Register_contactByPhonePrice")}</p>
                                            </div>
                                        </div>
                                        <div className="ctn_col col_right">
                                            <h2>{this.props.t("Register_formTitle")}</h2>
                                            <div className="obj_form">
                                                <div className="mb_30">
                                                    <CustomSelect
                                                        fieldName={"civilite"}
                                                        placeholder={this.props.t("Register_genderPlaceholder")}
                                                        options={[
                                                            { value: "0", label: this.props.t("Register_genderFemale") },
                                                            { value: "1", label: this.props.t("Register_genderMale") },
                                                            { value: "2", label: this.props.t("Register_genderNotDefined") },
                                                        ]}
                                                        setFieldValue={setFieldValue}
                                                        setFieldTouched={setFieldTouched}
                                                        errors={errors}
                                                        touched={touched}
                                                        values={values}
                                                    />
                                                </div>
                                                <div className="ctn_input mb_30">
                                                    <Field
                                                        className={errors.firstname && touched.firstname && "error"}
                                                        as="input"
                                                        type="text"
                                                        name="firstname"
                                                        placeholder={this.props.t("Register_firstnamePlaceholder")}
                                                    />
                                                </div>
                                                <div className="ctn_input mb_30">
                                                    <Field
                                                        className={errors.lastname && touched.lastname && "error"}
                                                        as="input"
                                                        type="text"
                                                        name="lastname"
                                                        placeholder={this.props.t("Register_lastnamePlaceholder")}
                                                    />
                                                </div>
                                                <div className="ctn_input mb_30 var_info">
                                                    <Field
                                                        className={errors.email && touched.email && "error"}
                                                        as="input"
                                                        type="email"
                                                        name="email"
                                                        placeholder={this.props.t("Register_emailPlaceholder")}
                                                    />
                                                    <div className="obj_infobulle">
                                                        <div className="info_picto">?</div>
                                                        <div className="info_text">
                                                            <span>{this.props.t("Register_emailDetails")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ctn_input mb_30">
                                                    <Field
                                                        className={errors.emailconfirm && touched.emailconfirm && "error"}
                                                        as="input"
                                                        type="email"
                                                        name="emailconfirm"
                                                        placeholder={this.props.t("Register_emailConfirm")}
                                                    />
                                                </div>
                                                {errors.emailconfirm && touched.emailconfirm && <p className="t_error mb_30 fade-in">{this.props.t("Register_invalidEmail")}</p>}
                                                <div className="ctn_input mb_30 var_password">
                                                    <PasswordToggle
                                                        setFieldValue={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        fieldName="password"
                                                        eyeMode={true}
                                                        placeholder={this.props.t("Register_passwordPlaceholder")}
                                                        strengthLevel={true}
                                                    />
                                                    <div className="obj_infobulle">
                                                        <div className="info_picto">?</div>
                                                        <div className="info_text">
                                                            <span>{this.props.t("Register_passwordDetails")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ctn_input mb_30">
                                                    <PasswordToggle
                                                        setFieldValue={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        fieldName="passwordconfirm"
                                                        eyeMode={true}
                                                        placeholder={this.props.t("Register_passwordConfirm")}
                                                    />
                                                </div>
                                                {errors.passwordconfirm && touched.passwordconfirm && <p className="t_error mb_30 fade-in">{this.props.t("Register_passwordError")}</p>}
                                                <div className="ctn_input mb_30 var_info">
                                                    <Field autoComplete="off" className={errors.phone && touched.phone && "error"} as="input" type="text" name="phone" placeholder={this.props.t("Register_PhonePlaceholder")} />
                                                    <div className="obj_infobulle">
                                                        <div className="info_picto">?</div>
                                                        <div className="info_text">
                                                            <span>{this.props.t("Register_PhoneDetail")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ctn_input mb_30 var_info">
                                                    <Field autoComplete="off" as="input" type="text" name="address" placeholder={this.props.t("Register_AdressPlaceholder")} />
                                                    <div className="obj_infobulle">
                                                        <div className="info_picto">?</div>
                                                        <div className="info_text">
                                                            <span>{this.props.t("Register_AdressDetail")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ctn_input mb_30 var_info">
                                                    <Field autoComplete="off" as="input" type="text" name="address_bis" placeholder={this.props.t("Register_Adress2Placeholder")} />
                                                    <div className="obj_infobulle">
                                                        <div className="info_picto">?</div>
                                                        <div className="info_text">
                                                            <span>{this.props.t("Register_Adress2Detail")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ctn_input mb_30 var_info">
                                                    <Field autoComplete="off" as="input" type="text" name="postal_code" placeholder={this.props.t("Register_CodePostalPlaceholder")} />
                                                    <div className="obj_infobulle">
                                                        <div className="info_picto">?</div>
                                                        <div className="info_text">
                                                            <span>{this.props.t("Register_CodePostalDetail")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ctn_input mb_30 var_info">
                                                    <Field autoComplete="off" as="input" type="text" name="city" placeholder={this.props.t("Register_CityPlaceholder")} />
                                                    <div className="obj_infobulle">
                                                        <div className="info_picto">?</div>
                                                        <div className="info_text">
                                                            <span>{this.props.t("Register_CityDetail")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <br />

                                            <h2>{this.props.t("Register_InformationsProfessionnelles")}</h2>
                                            <div className="obj_form">
                                                {apiDatas.Postes && (
                                                    <div className="mb_30">
                                                        <CustomSelect
                                                            fieldName={"poste"}
                                                            placeholder={this.props.t("Register_postePlaceholder")}
                                                            options={apiDatas.Postes.map((poste) => ({
                                                                value: poste.FrontOfficeUserPoste_Id,
                                                                label: poste.FrontOfficeUserPoste_Name,
                                                            }))}
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                )}

                                                <div className="ctn_checkbox_background mb_30">
                                                    <p className="t_title">{this.props.t("Register_AcceptNewsletter")}</p>
                                                    <div className="ctn_line">
                                                        <Field type="checkbox" id="acceptNwl" name="acceptNwl" className="switch_checkbox" />
                                                        <label className="switch_checkbox_label" htmlFor="acceptNwl">
                                                            <span></span> {this.props.t("Register_acceptNwl")}
                                                        </label>
                                                    </div>
                                                    <p className="t_desc">{this.props.t("Register_undoConsent")}</p>
                                                </div>
                                            </div>

                                            <br />
                                            
                                            {this.state.salePoint?.SalePointIsChallenge && <>
                                                <h2>{this.props.t("Register_Challenges")}</h2>
                                                <div className="obj_form">
                                                    <p className="t_mention mb_30">{this.props.t("Register_NeedSS")}</p>

                                                    <div className="ctn_input mb_30 var_info">
                                                        <Field
                                                            className={errors.securiteSociale && touched.securiteSociale && "error"}
                                                            as="input"
                                                            type="text"
                                                            name="securiteSociale"
                                                            placeholder={this.props.t("Register_SecuriteSociale")}
                                                        />
                                                    </div>
                                                    {errors.securiteSociale && touched.securiteSociale && <p className="t_error mb_30 fade-in">{this.props.t("Register_SecuriteSocialeInvalide")}</p>}
                                                    <div className="ctn_checkbox mb_30">
                                                        <Field type="checkbox" id="noSS" name="noSS" />
                                                        <label className="" htmlFor="noSS">
                                                            {this.props.t("Register_NoSS")}
                                                        </label>
                                                    </div>
                                                </div>
                                            </>}

                                            <div className="obj_form">
                                                <p
                                                    className="t_mention mb_30"
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.props.t("Register_CGU"),
                                                    }}
                                                ></p>

                                                <p className="t_mandatory mb_30">{this.props.t("Register_mandatory")}</p>

                                                <ReCaptcha
                                                    ref={(ref) => (this.recaptchaInscription = ref)}
                                                    sitekey={this.props.t("recaptcha_sitekey")}
                                                    action="contact"
                                                    verifyCallback={(recaptchaToken) => {
                                                        setFieldValue("recaptcha", recaptchaToken);
                                                    }}
                                                />

                                                {this.state.serverErrorRegistration && <p className="t_error mb_30 fade-in">{this.state.serverErrorMessageRegistration}</p>}
                                                {   ((errors.civilite && touched.civilite) ||
                                                    (errors.firstname && touched.firstname) ||
                                                    (errors.lastname && touched.lastname) ||
                                                    (errors.email && touched.email) ||
                                                    (errors.emailconfirm && touched.emailconfirm) ||
                                                    (errors.password && touched.password) ||
                                                    (errors.passwordconfirm && touched.passwordconfirm) ||
                                                    (errors.poste && touched.poste) ||
                                                    errors.recaptcha) && <p className="t_error mb_30 fade-in">{this.props.t("DefaultErrorMessage")}</p>
                                                }

                                                <div className="ctn_cta">
                                                    <button className="obj_cta" type="submit">
                                                        {this.props.t("Register_submit")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
								</form>
							)}
						</Formik>
					)}
				</div>
			</section>
		);
	}
}

export default withTranslation()(withSitecoreContext()(Inscription));
