import React, { Component, useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { ReCaptcha  } from "react-recaptcha-v3";
import { withTranslation } from "react-i18next";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

import DefaultModal from '../Modal/DefaultModal'
import ModalAjoutBeneficiaire from '../Modal/ModalAjoutBeneficiaire'

import IconSVG from "../../utilities/FrontFunctions/IconSVG"
import { isMobile } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions";
import { getApiDatas, postApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions";

const axios = require("axios");

class PageEventMyMembers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: isMobile(),
			apiDatas: [],
            modalConfirmDeleteText: "",
		};
	}

	componentDidMount() {
		getApiDatas(`/apiaox/Event/GetDataPageMesMembres`).then((apiDatas) => {
			this.setState({ apiDatas: apiDatas });
		});

		document.body.classList.add("pg_challenge-detail");
		window.scrollTo(0, 0);
	}
	componentWillUnmount() {
		document.body.classList.remove("pg_challenge-detail");
	}
	toggleDeleted = (e) => {
        var id = e.currentTarget.id.slice(17);
        this.setState({ selectedUserToDelete:id });
        var userData = this.state.apiDatas.Membres.find(o => o.Id == id);
        this.state.modalConfirmDeleteText = userData.Name + " : " + userData.Mail;
        this.ModalConfirmDelete.openModal();
	};
	userDeleted = (e) => {
		document.body.classList.add("is-loading");
		postApiDatas("/apiaox/Challenge/PostUserEnable", {
			UserId: this.state.selectedUserToDelete,
			Enable: false,
		}).then((res) => {
			document.body.classList.remove("is-loading");
			getApiDatas(`/apiaox/Event/GetDataPageMesMembres`).then((apiDatas) => {
				this.setState({ apiDatas: apiDatas });
			});
			if (!res.IsValid) {
				alert(res.Message);
			}
		});
	};

	render() {
		const apiDatas = this.state.apiDatas;
        const ROLE_TITULAIRE = "TITULS"; 

		let tableHeaders = [
			{ name: "", field: "id", sortable: false },
			{ name: "Prénom/Nom", field: "Name", sortable: true },
			{ name: "E-Mail", field: "Mail", sortable: true },
			{ name: "Rôle", field: "RoleName", sortable: true },
			{ name: "Membre Actif", field: "IsDeleted", sortable: false }
		];

		const TableHeader = ({ headers, handleSort, sortedField, sortDirection }) => {
			return (
				<div className="obj_table-header">
					{headers.map((header, index) => (
						<div
							key={index}
							className={`header-cell ${header.sortable ? "sortable" : ""}${header.field === sortedField && sortDirection === 1 ? " active" : ""}`}
							onClick={() => handleSort(header.field)}
						>
							<span dangerouslySetInnerHTML={{ __html: header.name }} />
						</div>
					))}
				</div>
			);
		};

		const TableRow = ({ rowData }) => {
			return (
				<React.Fragment>
					<div className={`table-row`}>
                        <div className="row-cell"></div>
                        <div className="row-cell">{rowData.Name}</div>
						<div className="row-cell">{rowData.Mail}</div>
						<div className="row-cell">{rowData.RoleName}</div>
						<div className="row-cell">
                            {   
                                rowData.RoleCode === ROLE_TITULAIRE
                                ? <input className="tgl tgl-light" id={`checkbox_deleted_${rowData.Id}`} type="checkbox" checked={!rowData.IsDeleted} disabled={true} />
                                : <input className="tgl tgl-light" id={`checkbox_deleted_${rowData.Id}`} type="checkbox" defaultChecked={!rowData.IsDeleted} onChange={(e) => this.toggleDeleted(e)} />
                            }
							<label className="tgl-btn" htmlFor={`checkbox_deleted_${rowData.Id}`}></label>
						</div>
					</div>
				</React.Fragment>
			);
		};

		const Table = ({ tableData, headers }) => {
			const [sortedField, setSortedField] = useState("");
			const [sortDirection, setSortDirection] = useState(1);

			const handleSort = (field) => {
				setSortedField(field);
				setSortDirection(sortDirection === 1 ? -1 : 1);
			};

			const sortedTableData = tableData.sort((a, b) => {
				if (!sortedField) return 0;
				if (a[sortedField] > b[sortedField]) {
					return sortDirection;
				} else if (a[sortedField] < b[sortedField]) {
					return -sortDirection;
				}
				return 0;
			});

			return (
				<div className="obj_tableau-beneficiaire">
					<TableHeader headers={headers} handleSort={handleSort} sortedField={sortedField} sortDirection={sortDirection} />
					{sortedTableData.map((rowData) => (
						<TableRow key={rowData.id} rowData={rowData}/>
					))}
				</div>
			);
		};

		return (
			<>
				<section className="obj_section section_challenges">
					<div className="ctn_section">
						<div className="ctn_title">
							<h1 className="t_title">Mes collaborateurs</h1>
						</div>

						{(apiDatas.Membres && apiDatas.User?.RoleCode == ROLE_TITULAIRE) && <>
                            <button 
                                className="obj_btn picto light obj_btn-title_absolute" 
                                type="button" 
                                onClick={() => this.ModalAjoutBeneficiaire.openModal()}
                            >
                                <span>Nouveau collaborateur</span>
                                <IconSVG icon="userPlus" />
					        </button>

                            {!this.state.isMobile 
                                ? <Table tableData={apiDatas.Membres} headers={tableHeaders} />
                                : <div class="obj_tableau-beneficiaire">
                                    {apiDatas.Membres.map((membre, index) => (
                                        <div class={`table-row${this.state.activeMenuGain ? " is-expanded" : ""}`} key={index}>
                                            <div class="table-header">
                                                <div class="row-cell">{membre.Name}</div>
                                            </div>
                                            <div class="table-ctn-row">
                                                <div class="header-cell">E-mail</div>
                                                <div class="row-cell">{membre.Mail}</div>
                                            </div>
                                            <div class="table-ctn-row">
                                                <div class="header-cell">Rôle</div>
                                                <div class="row-cell">{membre.RoleName}</div>
                                            </div>
                                            <div class="table-ctn-row">
                                                <div class="header-cell">Actif</div>
                                                <div class="row-cell">
                                                    {   
                                                        membre.RoleCode === ROLE_TITULAIRE
                                                        ? <input class="tgl tgl-light" id={`checkbox_deleted_${membre.Id}`} type="checkbox" checked={membre.IsDeleted} disabled={true} />
                                                        : <input class="tgl tgl-light" id={`checkbox_deleted_${membre.Id}`} type="checkbox" defaultChecked={membre.IsDeleted} onChange={(e) => this.toggleDeleted(e)} />
                                                    }
                                                    <label class="tgl-btn" htmlFor={`checkbox_deleted_${membre.Id}`}></label>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                            <ModalAjoutBeneficiaire
                                onRef={ref => (this.ModalAjoutBeneficiaire= ref)}
                            />

                            <DefaultModal 
                                onRef={(ref) => (this.ModalInvitation = ref)}
                                htmlContent={<>
                                    <p className="t_main">Inviter un nouveau membre</p>
                                    <div className="obj_form">
                                        <Formik
                                            initialValues={{
                                                email: "",
                                                recaptcha: "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                email: Yup.string().email().required(),
                                                recaptcha: Yup.string().required(),
                                            })}
                                            validateOnChange={false}    
                                            validateOnBlur={false}
                                            onSubmit={(values) => {
                                                document.body.classList.add("is-loading");

                                                postApiDatas('/apiaox/Call/Invitation', values)
                                                .then((res) => {
                                                    this.setState({
                                                        InvitationServerError: !res.IsValid,
                                                        InvitationServerErrorMessage: res.ExceptionMessage,
                                                    });

                                                    if(res.IsValid){
                                                        this.ModalInvitation.closeModal();
                                                        this.ModalInvitationConfirm.openModal();
                                                    }

                                                    if (this.recaptchaInvitation) {
                                                        this.recaptchaInvitation.execute();
                                                    }

                                                    document.body.classList.remove("is-loading");
                                                });
                                            }}
                                        >
                                            {({ handleSubmit, handleBlur, setFieldValue, errors, touched }) => (
                                                <form onSubmit={handleSubmit} name="InvitationForm">
                                                    <>
                                                        <div className="ctn_input mb_30">
                                                            <Field
                                                                className={errors.email && "error"}
                                                                as="input"
                                                                type="text"
                                                                name="email"
                                                                placeholder={this.props.t("Connexion_emailPlaceholder")}
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                        <p className="t_mandatory mb_30">{this.props.t("Connexion_mandatoryText")}</p>

                                                        <ReCaptcha
                                                            ref={(ref) => (this.recaptchaInvitation = ref)}
                                                            sitekey={this.props.t("recaptcha_sitekey")}
                                                            action="invitation"
                                                            verifyCallback={(recaptchaToken) => {
                                                                document.body.classList.remove('is-loading');
                                                                if (recaptchaToken.length > 0) {
                                                                    this.setState({
                                                                        ...this.state, 
                                                                        isRecaptchaLoaded: true, 
                                                                        errorMessage: ''
                                                                    });
                                                                    setFieldValue("recaptcha", recaptchaToken, false);
                                                                }
                                                            }}
                                                        />
                                                    </>

                                                    <>
                                                        {(errors.email) && (
                                                            <p className="t_error mb_20 fade-in">
                                                                {this.props.t("DefaultErrorMessage")}
                                                                {console.log(errors)}
                                                            </p>
                                                        )}
                                                        {(errors.recaptcha) && (
                                                            <p className="t_error mb_20 fade-in">
                                                                Le recaptcha n'a pas été initialisé correctement
                                                                {console.log('recapcha', errors)}
                                                            </p>
                                                        )}
                                                        {(this.state.InvitationServerError) && (
                                                            <p className="t_error mb_20 fade-in">
                                                                {this.state.InvitationServerErrorMessage}
                                                            </p>
                                                        )}
                                                    </>

                                                    <div className="ctn_cta mb_20">
                                                        <button name="submit_loginform" className="obj_cta" type="submit">
                                                            Inviter
                                                        </button>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </>
                                }
                            />
                        </>}
					</div>
				</section>
			</>
		);
	}
}

export default withTranslation()(withSitecoreContext()(PageEventMyMembers));
