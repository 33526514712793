import React, { Component } from 'react'

import { strDateToFr } from "../../utilities/FrontFunctions/AoxFrontFunctions"

class ObjTabPresences extends Component {
	constructor(props) {
		super(props)
		this.state = {
			openedBrand: {}
		}
		this.switchTab = this.switchTab.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
	}
	componentDidMount() {
		window.addEventListener('resize', this.throttleWindowResizeBrandSelection)
		window.dispatchEvent(new Event('resize'))
        this.switchTab(0);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.throttleWindowResizeBrandSelection)
	}

	switchTab(index){
        this.setState({openedBrand: {}});

		var $listItem = document.querySelectorAll(".ctn_nav-tabs li"),
            $tabItem = document.querySelectorAll(".ctn_content-tabs");

        $listItem.forEach(listItem => listItem.classList.remove("active"));
        $listItem[index].classList.add("active");
        $tabItem.forEach(tabItem => tabItem.classList.remove("active", "fade-in"));
        $tabItem[index].classList.add("active", "fade-in");

        window.dispatchEvent(new Event('scroll'));
	}

	toggleAccordion(index) {
        this.setState(prevState => ({
            openedBrand: {
                ...prevState.openedBrand,
                [index]: !prevState.openedBrand[index]
            }
        }));
    }
	
	render() {
		const { apiDatas } = this.props;

		return (<>
            {apiDatas.years?.length > 0 &&
                <div className="obj_tabs-list-attestation">

                    <ul className="ctn_nav-tabs">
                        {apiDatas.years.map((yearItem, index) => (
                            <li><button type="button" onClick={() => this.switchTab(index)}>Evénements {yearItem.year}</button></li>    
                        ))}
                    </ul>

                    {apiDatas.years.map((yearItem, indexYearItem) => (<>
                        <div className="ctn_content-tabs">
                            <div className="obj_accordion">
                                {yearItem.brands?.length > 0 && yearItem.brands.map((brandItem, indexBrandItem) => (<>
                                    <div key={indexBrandItem} className={`obj_accordion-row ${brandItem.cssClass} ${this.state.openedBrand[indexBrandItem]?'is_expanded':''}`}>
                                        <div className="obj_accordion-row-header">
                                            <button
                                                className="obj_accordion-title"
                                                onClick={() => this.toggleAccordion(indexBrandItem)}
                                            >
                                                <div className="obj_accordion_toggle-icon"></div>
                                                <h3 className="t_title">{brandItem.name}</h3>
                                            </button>
                                            <div className="obj_accordion-cta">
                                                <a href={brandItem.linkPdf} target="_blank">
                                                    <button className="obj_cta" >
                                                        Télécharger l'attestation
                                                    </button>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="ctn_accordion-content">
                                            <div className="obj_accordion-content">
                                                <div>
                                                    <div className="obj_table-header">
                                                        <div className="header-cell">Date - Heure</div>
                                                        <div className="header-cell">Evénement</div>
                                                        <div className="header-cell">Lieu</div>
                                                    </div>

                                                    {brandItem.presences.map((presenceItem, presenceItemIndex) => (
                                                        <div key={presenceItemIndex} className="ctn_rows">
                                                            <div className="expand_date_hour">{strDateToFr(presenceItem.date)}{presenceItem.hour && <> - {presenceItem.hour}</>}</div>
                                                            <div className="expand_event">{presenceItem.event}</div>
                                                            <div className="expand_location">{presenceItem.location}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>))}
                            </div>
                        </div>
                    </>))}

                </div>
            }</>
		)
	}
}

export default ObjTabPresences