import React, { Component } from 'react'

import { getApiDatas} from "../../utilities/FrontFunctions/AoxFrontFunctions"

import ObjTabPresences from './ObjTabPresences'

class PageMesPresences extends Component {
	constructor(props) {
		super(props)
		this.state = {
			apiDatas:[],
		}
	}
	componentDidMount() {
		getApiDatas(`/apiaox/Event/GetDataPageMesPresences`)
		.then((apiDatas) => { this.setState({ apiDatas: apiDatas }); });

		document.body.classList.add('pg_formation_attestation')
		window.scrollTo(0,0)
	}
	componentWillUnmount() {
		document.body.classList.remove('pg_formation_attestation')
	}
	render() {
		const apiDatas = this.state.apiDatas
        
		return (
			<>
                <section className="obj_section section_challenges-detail">
                    <div className="ctn_section">

                        <div className="ctn_title">
                            <h1 className="t_title">Mes attestations de présence</h1>
                            <p className="t_desc">Retrouvez les événements de formation auxquels vous avez participé classés par année et par marque.<br /> Sélectionnez l'année et la marque de votre choix pour télécharger vos attestations de présence au format PDF ou les imprimer.</p>
                        </div>

                        <div className="ctn_table_attestation">
                            {apiDatas.years && 
                                <ObjTabPresences apiDatas={apiDatas}/>
                            }
                        </div>
                        
                    </div>
                </section>
			</>
		)
	}
}

export default PageMesPresences